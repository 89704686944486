BR.Map = {

    initMap: function() {
        var map,
            layersControl,
            gcLayer,
            gcFoundsLayer;

        BR.keys = BR.keys || {};

        var maxZoom = 19;

       var autoTopo = L.tileLayer('https://mp.bubendorf.net/swissproxy/tiles/tp/{z}/{x}/{y}.jpeg', {
            maxZoom: 19,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'Test'
        });

/*        var topoCH = L.tileLayer('https://mp.bubendorf.net/tiles/at/{z}/{x}/{y}.jpeg', {
            maxZoom: 19,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'Topo CH'
        });
*/
        var topoCHBW = L.tileLayer('https://mp.bubendorf.net/tiles/at/{z}/{x}/{y}.jpeg', {
            maxZoom: 19,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'Topo CH-SW'
        });
	topoCHBW.setFilter(L.ImageFilters.GenerateCSSFilter(['grayscale(100%)', 'brightness(110%)', 'contrast(130%)' ]));

        var aerialCH = L.tileLayer('https://mp.bubendorf.net/swissproxy/tiles/si/{z}/{x}/{y}.jpeg', {
            maxZoom: 20,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'Swissimage'
        });

        var wanderwegeCH = L.tileLayer('https://mp.bubendorf.net/tiles/ww/{z}/{x}/{y}.png', {
            maxZoom: 19,
            maxNativeZoom: 17,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'Wanderwege CH'
        });

        var velowegeCH = L.tileLayer('https://mp.bubendorf.net/tiles/vw/{z}/{x}/{y}.png', {
            maxZoom: 19,
            maxNativeZoom: 17,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'Wanderwege CH'
        });

        var mozcoverage = L.tileLayer('https://d17pt8qph6ncyq.cloudfront.net/tiles/{z}/{x}/{y}.png', {
            maxZoom: 19,
            minZoom: 1,
            maxNativeZoom: 12,
            opacity: 1.0,
            attribution: 'MozCoverage'
        });
        mozcoverage.setFilter(L.ImageFilters.GenerateCSSFilter(['contrast(50%)', 'saturate(800%)', 'invert(100%)' ]));

//        var hillShading = L.tileLayer('http://{s}.tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png', {
//            maxZoom: 17,
//            maxNativeZoom: 15,
//            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
//            subdomains: 'abc'
//        });

        var hillShading = L.tileLayer('https://mp.bubendorf.net/tiles/hs/{z}/{x}/{y}.png', {
            maxZoom: 19,
            maxNativeZoom: 15,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

/*        var hillShadingTest = L.tileLayer('https://mp.bubendorf.net/tiles/hs/{z}/{x}/{y}.png', {
            maxZoom: 19,
            maxNativeZoom: 15,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
        hillShadingTest.setFilter(L.ImageFilters.GenerateCSSFilter(['url(filters.svg#contrast)']));
*/

        /*var contourLines = L.tileLayer('http://129.206.74.245/tiles/asterc/x={x}&y={y}&z={z}', {
                minZoom: 13,
                maxZoom: 17,
                attribution: 'Mapsurfer contours'
        });*/

        /*var contourLines = L.tileLayer.wms('http://geoweb.hft-stuttgart.de/cgi-bin/mapserv?map=/var/www/SRTM/test.map', {
                layers: 'Contourlinien50,Contourlinien100',
                crs: L.CRS.EPSG4326,
                format: 'image/png',
                transparent: true,
                uppercase: true,
                attribution: 'geoweb.hft-stuttgart.de'
        });*/

        var contourLines10 = L.tileLayer('https://mp.bubendorf.net/tiles/ct10/{z}/{x}/{y}.png', {
            minZoom: 13,
            maxZoom: 19,
            maxNativeZoom: 18,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'geoweb.hft-stuttgart.de'
        });
        var contourLines50 = L.tileLayer('https://mp.bubendorf.net/tiles/ct50/{z}/{x}/{y}.png', {
            minZoom: 11,
            maxZoom: 18,
            maxNativeZoom: 17,
            keepBuffer: 4,
            edgeBufferTiles: 1,
            attribution: 'geoweb.hft-stuttgart.de'
        });

        var osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: maxZoom
        });

        var osmde = L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png', {
            maxNativeZoom: 18,
            maxZoom: maxZoom
        });

        var topo = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
            maxNativeZoom: 17,
            maxZoom: maxZoom
        });

        var thunderforestAttribution = 'tiles &copy; <a target="_blank" href="https://www.thunderforest.com">Thunderforest</a> '
            + '(<a target="_blank" href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a>)';
        var thunderforestAuth = BR.keys.thunderforest ? '?apikey=' + BR.keys.thunderforest : '';

        var cycle = L.tileLayer('https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png' + thunderforestAuth, {
            maxNativeZoom: 18,
            maxZoom: maxZoom
        });

        var outdoors = L.tileLayer('https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png' + thunderforestAuth, {
            maxNativeZoom: 18,
            maxZoom: maxZoom
        });

        var esri = L.tileLayer('https://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            maxNativeZoom: 19,
            maxZoom: maxZoom,
            subdomains: ['server', 'services'],
            attribution: '<a target="_blank" href="http://goto.arcgisonline.com/maps/World_Imagery">World Imagery</a> '
                + '&copy; <a target="_blank" href="https://www.esri.com/">Esri</a>, sources: '
                + 'Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, and the GIS User Community'
        });

        var cycling = L.tileLayer('https://tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png', {
          maxNativeZoom: 18,
          opacity: 0.7,
          maxZoom: maxZoom
        });

        var hiking = L.tileLayer('https://tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png', {
          maxNativeZoom: 18,
          opacity: 0.7,
          maxZoom: maxZoom
        });

        map = new L.Map('map', {
            contextmenu: true,
            contextmenuWidth: 140,
            contextmenuItems: [{
                text: 'Copy coordinates',
                callback: function (e) {
                    var coords = MBUUtil.formatLatLng(e.latlng);
                    clipboard.writeText(coords);
                    alert('Koordinaten kopiert!\n' + coords);
                }
            },{
                text: 'Toggle tooltips',
                callback: function (e) {
                    gcLayer.toggleTooltips();
                    gcFoundsLayer.toggleTooltips();
                }
            },/*{
                text: 'Toggle Coordinats',
                callback: function (e) {
                }
            },{
                text: 'Toggle Elevation',
                callback: function (e) {
                   elevationControl.visible = !elevationControl.visible;
                   console.log('elevation.visible=' + elevationControl.visible);
                }
            },*/
            '-',
            {
                text: 'Center map here',
                callback: function (e) {
                    map.panTo(e.latlng);
                }
            },
            {
                text: 'Zoom in',
                icon: 'images/zoom-in.png',
                callback: function (e) {
                    map.zoomIn();
                }
            }, {
                text: 'Zoom out',
                icon: 'images/zoom-out.png',
                callback: function (e) {
                    map.zoomOut();
                }
            }],
//            attributionControl: false,
            layers: [autoTopo], 
            center: new L.LatLng(47.1, 7.6),
            zoom: 11,
            zoomSnap: 0.5,
            zoomDelta: 0.5,
            scrollWheelZoom: true,
            wheelPxPerZoomLevel: 85,
            worldCopyJump: true,
            keyboardPanDelta: 154
        });
        if (!map.restoreView()) {
            map.setView([47.1, 7.6], 11);
        }
//        map.attributionControl.setPrefix(false);
//        map.attributionControl.addAttribution('<a href="" data-toggle="modal" data-target="#credits">Copyright & credits</a>')
        document.getElementsByClassName( 'leaflet-control-attribution' )[0].style.display = 'none';

        var baseLayers = {
            'Topo': autoTopo,
	    'Topo CH S/W': topoCHBW,
//            'AutoTopo': autoTopo,
            'Luftbild CH': aerialCH,
            'OpenStreetMap': osm,
            'OpenStreetMap.de': osmde,
            'OpenTopoMap': topo,
            'OpenCycleMap (Thunderf.)': cycle,
            'Outdoors (Thunderforest)': outdoors,
            'Esri World Imagery': esri
        };

        gcLayer = BR.GCLayer.init(map, 'https://br.bubendorf.net/brouter/brouter/geojson/{z}/{x}/{y}.json?db=default&type=caches');
        gcFoundsLayer = BR.GCLayer.init(map, 'https://br.bubendorf.net/brouter/brouter/geojson/{z}/{x}/{y}.json?db=founds&type=caches');
        var gcDeletedLayer = BR.GCLayer.init(map, 'https://br.bubendorf.net/brouter/brouter/geojson/{z}/{x}/{y}.json?db=deleted&type=caches');
        var wpLayer = BR.GCLayer.init(map, 'https://br.bubendorf.net/brouter/brouter/geojson/{z}/{x}/{y}.json?db=default&type=waypoints');

        var overlays = {
             'Geocaches': gcLayer.gcmarkers,
             'GC Wegpunkte': wpLayer.gcmarkers,
             'Gefundene Geocaches': gcFoundsLayer.gcmarkers,
             'Gelöschte Geocaches': gcDeletedLayer.gcmarkers,
             'Velowege CH': velowegeCH,
             'Wanderwege CH': wanderwegeCH,
             'MozCoverage': mozcoverage,
             'Schattierung': hillShading,
//             'Schattierung Test': hillShadingTest,
             'H&ouml;henlinien 10m': contourLines10,
             'H&ouml;henlinien 50m': contourLines50,
             'Cycling (Waymarked Trails)': cycling,
             'Hiking (Waymarked Trails)': hiking
        };

        if (BR.keys.bing) {
            baseLayers['Bing Aerial'] = new BR.BingLayer(BR.keys.bing);
        }

        if (BR.keys.digitalGlobe) {
            var recent = new L.tileLayer('https://{s}.tiles.mapbox.com/v4/digitalglobe.nal0g75k/{z}/{x}/{y}.png?access_token=' + BR.keys.digitalGlobe, {
                minZoom: 1,
                maxZoom: 19,
                attribution: '&copy; <a href="https://www.digitalglobe.com/platforms/mapsapi">DigitalGlobe</a> ('
                           + '<a href="https://bit.ly/mapsapiview">Terms of Use</a>)'
            });
            baseLayers['DigitalGlobe Recent Imagery'] = recent;
        }

        if (BR.conf.clearBaseLayers) {
            baseLayers = {};
        }
        for (i in BR.conf.baseLayers) {
            if (BR.conf.baseLayers.hasOwnProperty(i)) {
                baseLayers[i] = L.tileLayer(BR.conf.baseLayers[i]);
            }
        }

        for (i in BR.conf.overlays) {
            if (BR.conf.overlays.hasOwnProperty(i)) {
                overlays[i] = L.tileLayer(BR.conf.overlays[i]);
            }
        }
        // after applying custom base layer configurations, add first base layer to map
        var firstLayer = baseLayers[Object.keys(baseLayers)[0]];
        if (firstLayer) {
            map.addLayer(firstLayer);
        }

        layersControl = L.control.layers(baseLayers, overlays).addTo(map);

        L.control.locate({
            icon: 'fa fa-location-arrow',
            iconLoading: 'fa fa-spinner fa-pulse',
        }).addTo(map);

        L.control.scale().addTo(map);

        new BR.Layers().init(map, layersControl, baseLayers, overlays);

        // expose map instance for console debugging
        BR.debug = BR.debug || {};
        BR.debug.map = map;

        var layersAndOverlays = baseLayers;
        for (var o in overlays) {
            layersAndOverlays[o] = overlays[o];
        }
        return {
            map: map,
            layersControl: layersControl,
            layers: layersAndOverlays
        };
    }

};
