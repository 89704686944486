L.Control.ElevationControl = L.Control.extend({
  options: {
    position: 'bottomleft',
    emptyString: 'Nicht verfügbar',
    url: 'https://br.bubendorf.net/elevation/elevation?'
  },
  lat: 0,
  lng: 0,
  timerId: 0,
  visible: true,

  onAdd: function (map) {
    this._container = L.DomUtil.create('div', 'leaflet-control-elevation');
    L.DomEvent.disableClickPropagation(this._container);
    map.on('mousemove', this._onMouseMove, this);
    this._container.innerHTML=this.options.emptyString;
    return this._container;
  },

  onRemove: function (map) {
    map.off('mousemove', this._onMouseMove)
  },

  _onMouseMove: function (e) {
    if (!this.visible) {
        return;
    }
    var latNew = e.latlng.lat;
    var lngNew = e.latlng.lng;
    var dist = this.getDistance(this.lat, this.lng, latNew, lngNew);
//    console.log(dist);
    if (dist >= 5) {
      this._container.innerHTML = "";
      this.lat = latNew;
      this.lng = lngNew;
      clearTimeout(this.timerId);
      var that = this;
      this.timerId = setTimeout(function(){that.updateElevation()}, 100);
    }
  },

  updateElevation: function() {
    var xhttp = new XMLHttpRequest();
    xhttp.that = this;
    xhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
//        console.log(xhttp.responseText);
        xhttp.that._container.innerHTML =  xhttp.responseText + 'm';
      }
    };
    xhttp.open("GET", this.options.url + 'lat=' + this.lat.toFixed(6) + '&lon=' + this.lng.toFixed(6) , true);
    xhttp.send();
  },

  getDistance: function(lat1, lon1, lat2, lon2) {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + 
          c(lat1 * p) * c(lat2 * p) * 
          (1 - c((lon2 - lon1) * p))/2;
    return 1000 * 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

});

L.Map.mergeOptions({
    positionControl: false
});

L.Map.addInitHook(function () {
    if (this.options.positionControl) {
        this.positionControl = new L.Control.ElevationControl();
        this.addControl(this.positionControl);
    }
});

L.control.elevationControl = function (options) {
    return new L.Control.ElevationControl(options);
};
