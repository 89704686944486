
var oldGeocode = L.Control.Geocoder.Nominatim.prototype.geocode;
var googleGeocoder = new L.Control.Geocoder.Google('AIzaSyDCgHePMW0A4z74U35EMTvy-KUyYp6kwzo ', {
});

L.Control.Geocoder.Nominatim.prototype.geocode = function(query, cb, context) {
  //console.log(query);

  var gcCoordsRegex = /([+-]*\d{1,2})\D+([0-9.]+)\D+([+-]*\d{1,3})\D+([0-9.]+)/;
  var match = query.match(gcCoordsRegex);
  if (match) {
    var lat = Number(match[1]) + Number(match[2])/60;
    var lon = Number(match[3]) + Number(match[4])/60;

    var results = [];
    results[0] = {
      name: 'Manual Coordinate: ' + query,
      bbox: L.latLngBounds([lat, lon], [lat, lon]),
      center: L.latLng(lat, lon),
      properties: {}
    };
    cb.call(context, results);
  } else {
    // Keine Koordinate ==> Nominatim mit der Suche beauftragen
    oldGeocode.call(this, query, function (results){
//console.log(results);
      if (results && results.length > 0) {
        // Aha. Etwas wurde gefunden. Das verwenden wir doch gleich!
        cb.call(context, results);
      } else {
        // Sonst mal Google fragen was die meinen...
        googleGeocoder.geocode(query, cb, context);
      }
    }, context);
  }
};
