BR.Search = L.Control.Geocoder.extend({
    options: {
        geocoder: new L.Control.Geocoder.Nominatim({
            serviceUrl: 'https://nominatim.openstreetmap.org/',
            geocodingQueryParams: {
//                countrycodes: 'ch',
                limit: 10,
                polygon_geojson : 1
            }
        }),
//         geocoder: new L.Control.Geocoder.Google('AIzaSyDCgHePMW0A4z74U35EMTvy-KUyYp6kwzo ', {
//         }),
        showResultIcons: true,
        position: 'topleft'
    },

    onAdd: function (map) {
        // Überschreiben der suggest Methode im Geocoder
/*        this.options.geocoder.suggest = function(query, cb, context) {
	    return this.geocode(query, cb, context);
        };
*/
        map.attributionControl.addAttribution(
            'search by <a href="https://wiki.openstreetmap.org/wiki/Nominatim" target="_blank">Nominatim</a>');

        return L.Control.Geocoder.prototype.onAdd.call(this, map);
    },

    markGeocode: function(result) {
        this._map.fitBounds(result.geocode.bbox, {
            maxZoom: 16
        });

        this.clear();
        this._geocodeMarker = new L.CircleMarker(result.geocode.center, {
            interactive: false,
            color: 'red',
            opacity: 0.8,
            fillOpacity: 0.2,
            weight: 2
        }).addTo(this._map);

        if (result.geocode.properties.geojson) {
           if (result.geocode.properties.geojson.type === 'Polygon') {
               this._areaMarker = L.geoJSON(result.geocode.properties.geojson, {
                   color: 'blue',
                   opacity: 0.5,
                   fillOpacity: 0.1,
                   weight: 1
               }).addTo(this._map);
           }
        } else if (result.geocode.bbox) {
            var latDiff = result.geocode.bbox._northEast.lat - result.geocode.bbox._southWest.lat;
            var lngDiff = result.geocode.bbox._northEast.lng - result.geocode.bbox._southWest.lng;
            if (latDiff + lngDiff >= 0.001) {
               this._areaMarker = new L.rectangle(result.geocode.bbox, {
                    color: 'blue',
                    opacity: 0.5,
                    fillOpacity: 0.1,
                    weight: 1
                }).addTo(this._map);
            }
	}
        return this;
    },

    clear: function() {
        if (this._geocodeMarker) {
            this._map.removeLayer(this._geocodeMarker);
        }
       if (this._areaMarker) {
            this._map.removeLayer(this._areaMarker);
        }
    }
});
