BR.RoutingOptions = BR.Control.extend({

    onAdd: function (map) {
		this._map = map;
        $('#profile').on('changed.bs.select', this._getChangeHandler());
        $('#alternative').on('changed.bs.select', this._getChangeHandler());
        $('#maxgcdistance').on('changed.bs.select', this._getChangeHandler());
        $('#togowaypoints').on('changed.bs.select', this._gotoCoordinate());

        // build option list from config
        var profiles = BR.conf.profiles;
        var profiles_list = L.DomUtil.get('profile');
        for (var i = 0; i < profiles.length; i++) {
            var option = document.createElement("option");
            option.value = profiles[i];
            option.text = profiles[i].replace('Profiles', '').replace('pou/', '');
            profiles_list.appendChild(option);
        }
        /* if (BR.conf.getprofilesUrl) {
           BR.Util.get(BR.conf.getprofilesUrl, function(err, profiles) {
              if (err) {
                   console.warn('Error: ' + err);
                   return;
              }
              var lines = profiles.split('\n');
              var profiles_list = L.DomUtil.get('profile');
              for(var line = 0; line < lines.length; line++){
                 if (!lines[line].startsWith('=')) {
                    console.warn(lines[line]);
                    var option = document.createElement("option");
                    option.value = lines[line];
                    option.text = lines[line].replace('Profiles', '');
                    profiles_list.appendChild(option);
                 }
              }
          });
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker('render');
        } */
        // <custom> profile is empty at start, select next one
        profiles_list.children[1].selected = true;
        return BR.Control.prototype.onAdd.call(this, map);
    },

    refreshUI: function() {
        var profile = $('#profile option:selected'),
            alternative = $('#alternative option:selected'),
            maxgcdistance = $('#maxgcdistance option:selected');

        $('#stat-profile').html(profile.text() + ' (' + alternative.text() +')');

        // we do not allow to select more than one profile and/or alternative at a time
        // so we disable the current selected items
        $('#profile-alternative').find('option:disabled').each(function(index) {
            $(this).prop('disabled', false);
        });
        $('#profile-alternative').find('option:selected').each(function(index) {
            $(this).prop('disabled', true);
        });

        // disable custom option if it has no value yet (default value is "Custom")
        var custom = L.DomUtil.get('profile').children[0];
        if (custom.value === "Custom") {
            custom.disabled = true;
        }
        $('.selectpicker').selectpicker('refresh')
    },

    getOptions: function() {
        var profile = $('#profile option:selected'),
            alternative = $('#alternative option:selected'),
            maxgcdistance = $('#maxgcdistance option:selected');
        this.refreshUI();

        return {
            profile: profile.val(),
            alternative: alternative.val(),
            maxgcdistance: maxgcdistance.val()
        };
    },

    setOptions: function(options) {
        var values = [
            options.profile ? options.profile : $('#profile option:selected').val(),
            options.alternative ? options.alternative : $('#alternative option:selected').val(),
            options.maxgcdistance ? options.maxgcdistance : $('#maxgcdistance option:selected').val()
        ];
        $('.selectpicker').selectpicker('val', values);
        this.refreshUI();

        if (options.profile) {
            // profile got not selected = not in option values -> custom profile passed with permalink
            if (L.DomUtil.get('profile').value != options.profile) {
                this.setCustomProfile(options.profile, true);
            }
        }
        if (options.maxgcdistance) {
          if (L.DomUtil.get('maxgcdistance').value != options.maxgcdistance) {
                // Keine oder eine 'schraege' MaxGCDistanz ==> Zur Auswahl hinzufügen und gleich selektieren
               $("#maxgcdistance").append('<option value="' + options.maxgcdistance + '">' + options.maxgcdistance + 'm</option>');
               // Sort Items by Value
               $('#maxgcdistance' + ' option').sort(function(a, b) {
                  return +a.value > +b.value;
               }).appendTo($("#maxgcdistance"));
               $('select[id=maxgcdistance]').val(options.maxgcdistance);
               $('.selectpicker').selectpicker('refresh');
            }
        }
    },

    setCustomProfile: function(profile, noUpdate) {
        var profiles_grp,
            option;

        profiles_grp = L.DomUtil.get('profile');
        option = profiles_grp.children[0]
        option.value = profile;
        option.disabled = !profile;

        $('#profile').find('option:selected').each(function(index) {
            $(this).prop('selected', false);
        });

        option.selected = !!profile;

        if (!noUpdate) {
            this.fire('update', {options: this.getOptions()});
        }
    },

    getCustomProfile: function() {
        var profiles_grp = L.DomUtil.get('profile'),
            option = profiles_grp.children[0],
            profile = null;

        if (!option.disabled) {
            profile = option.value;
        }
        return profile;
    },

    _getChangeHandler: function() {
        return L.bind(function(evt) {
            $('.selectpicker').selectpicker('toggle')
            this.fire('update', {options: this.getOptions()});
        }, this);
    },
	
    _gotoCoordinate: function(e, clickedIndex, newValue, oldValue) {
        return L.bind(function(evt) {
//            console.log("Goto Coordinate: " + JSON.stringify(evt.target.value));
			var gcCoordsRegex = /(\d{1,2})\D+([0-9.]+)\D+(\d{1,3})\D+([0-9.]+)/;
			var match = evt.target.value.match(gcCoordsRegex);
			if (match) {
				var latDegree = Number(match[1]);
				var latMinutes = Number(match[2]);
				var lonDegree = Number(match[3]);
				var lonMinutes = Number(match[4]);
				var lat = latDegree + latMinutes/60;
				var lon = lonDegree + lonMinutes/60;
				this._map.flyTo(new L.LatLng(lat, lon));
			}
        }, this);
    }

});

BR.RoutingOptions.include(L.Mixin.Events);
