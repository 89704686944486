MBUUtil = {
	formatLatLng: function(latlng) {
		return MBUUtil.latFormatter(latlng.lat) + ' ' + MBUUtil.lonFormatter(latlng.lng);
	},

	latFormatter: function(lat) {
		return MBUUtil.formatCoords(lat, 'N', 'S');
	},

	lonFormatter: function(lon) {
		return MBUUtil.formatCoords(lon, 'E', 'W');
	},

	formatCoords: function(value, pos, neg) {
		var posVal = Math.abs(value);
		var degree = Math.floor(posVal);
		var minutes = (posVal - degree) * 60;
		var text = degree + ' ' + minutes.toFixed(3);
		return (value > 0 ? pos : neg) + text;
	},

	distFormatter: function(dist) {
		if (dist >= 10000) {
			return L.Util.formatNum(dist / 1000, 3) + 'km';
		}
		return dist + 'm';
	}
};

BR.Geocaches = L.Class.extend({

		options: {
			heading: 'Geocaches',
			cacheStyle: {
				radius: 14,
				color: 'yellow',
				fill: true,
				opacity: 0.9,
				fillOpacity: 0.4,
				weight: 4,
				pane: 'tooltipPane'
			}
		},

		// true when tab is shown, false when hidden
		active: true,

		columnOptions: {
			'Number': {
				title: 'Num',
				data: 'Code',
				render: function (data, type, row, meta) {
					return meta.row + 1;
				},
				className: 'dt-body-right',
				visible: true
			},
			'Corrected': {
				title: 'Cor',
				data: 'Cor',
				render: function (data, type, row) {
					return data ? '*' : '';
				},
				className: 'dt-body-center',
				visible: false
			},
			'Code': {
				title: 'Code',
				data: 'Code',
				className: 'dt-body-left',
				visible: false
			},
			'Latitude': {
				title: 'Lat',
				data: 'Lat',
				render: function (data, type, row) {
					return MBUUtil.latFormatter(data);
				},
				className: 'dt-body-left',
				visible: false
			},
			'Longitude': {
				title: 'Lon',
				data: 'Lon',
				render: function (data, type, row) {
					return MBUUtil.lonFormatter(data);
				},
				className: 'dt-body-left',
				visible: false
			},
			'Coordinates': {
				title: 'Coords',
				data: 'Code',
				render: function (data, type, row) {
					return MBUUtil.latFormatter(row.Lat) + ' ' + MBUUtil.lonFormatter(row.Lon);
				},
				className: 'dt-body-left',
				visible: false
			},
			'Name': {
				title: 'Name',
				data: 'Name',
				render: function (data, type, row) {
					return data + (row.Cor ? ' *' : '');
				},
				className: 'dt-body-left'
			},
			'Type': {
				title: 'Type',
				data: 'Type',
				className: 'dt-body-center'
			},
			'Difficulty': {
				title: 'Dif',
				data: 'Dif',
				className: 'dt-body-center'
			},
			'Terrain': {
				title: 'Ter',
				data: 'Ter',
				className: 'dt-body-center'
			},
                       'Elevation': {
                                title: 'Ele',
                                data: 'Ele',
                                render: function (data, type, row) {
                                     return data + "m";
                                },
                                className: 'dt-body-left',
                                visible: true
                        },
			'DistanceFromTrack': {
				title: 'Dist',
				data: 'Dist',
				render: function (data, type, row) {
					return MBUUtil.distFormatter(data);
				},
				className: 'dt-body-right'
			},
			'PositionInSegment': {
				title: 'PosSeg',
				data: 'Pos',
				render: function (data, type, row) {
					return MBUUtil.distFormatter(data);
				},
				className: 'dt-body-right',
				visible: false
			},
			'PositionFromStart': {
				title: 'Pos',
				data: 'PosStart',
				render: function (data, type, row) {
					return MBUUtil.distFormatter(data);
				},
				className: 'dt-body-right'
			}
		},

		initialize: function (options) {
			L.setOptions(this, options);

			var table = document.getElementById('geocachestable');
			this.tableClassName = table.className;
			this.tableParent = table.parentElement;
		},

		onAdd: function (map) {
			this._map = map;
		},

		update: function (segments) {
			var i,
			geocaches,
			columns,
			headings,
			data = [];

			if (!this.active) {
				return;
			}

			// Die Geocache Daten der einzelnen Segmente zusammenfügen
			var trackLengh = 0;
			for (i = 0; segments && i < segments.length; i++) {
				geocaches = segments[i].feature.properties.geocaches;
				if (geocaches) {
					// Aber auch gleich noch die Position innerhalb des Gesamtpfades anpassen
					for (j = 0; j < geocaches.length; j++) {
						geocaches[j].PosStart = geocaches[j].Pos + trackLengh;
					}
					data = data.concat(geocaches);
				}
				trackLengh += +segments[i].feature.properties['track-length'];
			}

			if (data.length === 0) {
				return;
			}

			// ToDo: Direkt aus TrackStats übernehmen
			var dest = [];
			for (i = 0; i < data.length; i++) {
				var code = data[i].Code;
				// Den Code im dest-Array suchen
				for (j = 0; j < dest.length; j++) {
					if (dest[j].Code === code) {
						break;
					}
				}
				if (j >= dest.length) {
					// Code nicht gefunden ==> Neuer Cache an die dest Liste anhängen
					dest.push(data[i]);
				} else {
					// Cache ist schon in der dest Liste ==>
					// Je nach dem diesen behalten oder löschen und den neuen ans Ende anfügen!
					if (dest[j].Dist <= data[i].Dist) {
						// Behalten
						//						console.log('Doppelter Cache: ' + code + ' behalte bisherige Kopie da ' + dest[j][6] + ' <= ' + data[i][6]);
					} else {
						// Löschen und neuer Cache ans Ende anfügen
						//						console.log('Doppelter Cache: ' + code + ' verschiebe ans Ende da ' + dest[j][6] + ' > ' + data[i][6]);
						dest.splice(j, 1);
						dest.push(data[i]);
					}
				}
			}
			data = dest;

			headings = ["Number", "Name", "Corrected", "Code", "Latitude", "Longitude", 
                                "Coordinates", "Type", "Difficulty",
				"Terrain", "Elevation", "DistanceFromTrack", "PositionInSegment", "PositionFromStart"];
			columns = this._getColumns(headings, data);

			this._table = $('#geocachestable').DataTable({
					dom: 'frtipB',
					autoWidth: true,
					destroy: true,
					data: data,
					columns: columns,
					paging: false,
					searching: true,
					info: false,
					scrollX: true,
					ordering: true,
					buttons: [{
							extend: 'copyHtml5',
							title: 'BRouter',
							exportOptions: {
								columns: ':visible'
							},
							messageBottom: L.bind(this._infotext, this)
						}, {
							extend: 'csvHtml5',
							title: 'BRouter',
							exportOptions: {
								columns: ':visible'
							},
							messageBottom: L.bind(this._infotext, this)
						}, {
							extend: 'excelHtml5',
							title: 'BRouter',
							exportOptions: {
								columns: ':visible'
							},
							messageTop: L.bind(this._messageTop, this),
							messageBottom: L.bind(this._infotext, this)
						}, {
							extend: 'pdfHtml5',
							title: 'BRouter',
							exportOptions: {
								columns: ':visible'
							},
							messageTop: L.bind(this._messageTop, this),
							messageBottom: L.bind(this._infotext, this) /*,
							customize: function (doc) {
								doc.content[2].table.widths = Array(doc.content[2].table.body[0].length + 1).join('*').split('');
							}*/
						}, {
							extend: 'print',
							title: 'BRouter',
							exportOptions: {
								columns: ':visible'
							},
							messageTop: L.bind(this._messageTop, this),
							messageBottom: L.bind(this._infotextHTML, this)
						}, {
							extend: 'colvis',
							text: 'Columns'
						}
					],
					colReorder: true,
					order: []
				});

			// highlight Geocache on row hover
			$('#geocachestable tbody tr').hover(L.bind(this._handleHover, this), L.bind(this._handleHoverOut, this));
		},

		show: function () {
			this.active = true;
			this.options.requestUpdate(this);
		},

		hide: function () {
			this.active = false;
		},

		_messageTop: function () {
			return new Date().toLocaleString('de-CH');
		},

		_infotext: function () {
			return this.options.trackStats.infotext;
		},

		_infotextHTML: function () {
			return this.options.trackStats.infotext.replace(/\n/g, '<br />');
		},

		_getColumns: function (headings, data) {
			var columns = [],
			defaultOptions,
			options;

			for (k = 0; k < headings.length; k++) {
				defaultOptions = {
					title: headings[k],
					visible: true
				};
				options = L.extend(defaultOptions, this.columnOptions[headings[k]]);
				columns.push(options);
			}
			return columns;
		},

		_handleHover: function (evt) {
			var tr = $(evt.currentTarget);
			var row = this._table.row(tr);
			var cache = this._table.data()[row.index()];
			this._selectedCache = L.circleMarker([cache.Lat, cache.Lon], this.options.cacheStyle).addTo(this._map);
		},

		_handleHoverOut: function (evt) {
			this._map.removeLayer(this._selectedCache);
			this._selectedCache = null;
		}

	});

BR.Geocaches.include(L.Mixin.Events);
