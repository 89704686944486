BR.GCLayer = {

    init: function (map, baseurl) {

        //    console.info('GCLayer init');

        var gcmarkers = L.markerClusterGroup({
                showCoverageOnHover: true,
                disableClusteringAtZoom: 13,
                maxClusterRadius: 80
            });
        gcmarkers.caches = {};
        gcmarkers.markers = [];
        gcmarkers.tooltipsVisible = false;
        gcmarkers.baseurl = baseurl;

        map.on('overlayadd', function (layerEvent) {
            if (layerEvent.layer == gcmarkers && gcmarkers.loaded != true) {
                // Mark the layer as 'visible'
                gcmarkers.loaded = true;
                loadCaches(map.getBounds());
            }
        });

        map.on('overlayremove', function (layerEvent) {
            if (layerEvent.layer == gcmarkers) {
                gcmarkers.loaded = false;
            }
        });

        map.on('moveend', function (event) {
            loadCaches(map.getBounds());
        });

        /** Converts numeric degrees to radians */
        if (typeof(Number.prototype.toRad) === "undefined") {
            Number.prototype.toRad = function () {
                return this * Math.PI / 180;
            }
        }

		function createIcons(iconUrl, iconSize) {
			var IconBase = L.Icon.extend({
                options: {
                    iconUrl: iconUrl,
                    iconSize: iconSize,
                    shadowSize: [22, 22]
                }
            });
			var icons = {
				'defaultAvailable': new IconBase({shadowUrl: 'images/bgDefaultAvailable.gif'}),
				'defaultDisabled': new IconBase({shadowUrl: 'images/bgDefaultDisabled.gif'}),
				'defaultArchived': new IconBase({shadowUrl: 'images/bgDefaultArchived.gif'}),
				'correctedAvailable': new IconBase({shadowUrl: 'images/bgCorrectedAvailable.gif'}),
				'correctedDisabled': new IconBase({shadowUrl: 'images/bgCorrectedDisabled.gif'}),
				'correctedArchived': new IconBase({shadowUrl: 'images/bgCorrectedArchived.gif'}),
				'foundAvailable': new IconBase({shadowUrl: 'images/bgFoundAvailable.gif'}),
				'foundDisabled': new IconBase({shadowUrl: 'images/bgFoundDisabled.gif'}),
				'foundArchived': new IconBase({shadowUrl: 'images/bgFoundArchived.gif'})
			};
			return icons;
		}
		
		function createWPIcons(iconUrl, iconSize) {
			var IconBase = L.Icon.extend({
                options: {
                    iconUrl: iconUrl,
                    iconSize: iconSize
                }
            });
			var icons = {
				'defaultAvailable': new IconBase()
			};
			return icons;
		}
		
        function loadCaches(bounds) {
            if (gcmarkers.loaded != true) {
                // Do not load anything if the layer is not (or no more) visible!
                return;
            }
            var zoom = 11;
            var xMin = parseInt(Math.floor((bounds.getWest() + 180) / 360 * (1 << zoom)));
            var xMax = parseInt(Math.floor((bounds.getEast() + 180) / 360 * (1 << zoom)));
            var latRad = bounds.getNorth().toRad();
            var yMin = parseInt(Math.floor((1 - Math.log(Math.tan(latRad) + 1 / Math.cos(latRad)) / Math.PI) / 2 * (1 << zoom)));
            latRad = bounds.getSouth().toRad();
            var yMax = parseInt(Math.floor((1 - Math.log(Math.tan(latRad) + 1 / Math.cos(latRad)) / Math.PI) / 2 * (1 << zoom)));

            //      console.info('Tiles ' + xMin + '/' + yMin + ' to ' + xMax + '/' + yMax);
            for (x = xMin; x <= xMax; x++) {
                for (y = yMin; y <= yMax; y++) {
                    loadCachesAt(x, y, zoom);
                }
            }
        }

        var tradiIcons = createIcons('images/traditional_72.gif', [24, 24]);
        var unknownIcons = createIcons('images/unknown_72.gif', [24, 24]);
        var multiIcons = createIcons('images/multi_72.gif', [24, 24]);
        var letterIcons = createIcons('images/letter_72.gif', [24, 24]);
        var earthIcons = createIcons('images/earthcache_80.gif', [22, 22]);
        var virtualIcons = createIcons('images/virtual_72.gif', [24, 24]);
        var eventIcons = createIcons('images/event_72.gif', [24, 24]);
        var wherigoIcons = createIcons('images/wherigo_72.gif', [24, 24]);

        var icons = {
            'A': undefined,
            'B': letterIcons,
            'C': eventIcons,
            'D': eventIcons,
            'E': eventIcons,
            'F': eventIcons,
            'G': undefined,
            'H': eventIcons,
            'I': wherigoIcons,
            'J': undefined,
            'K': undefined,
            'L': virtualIcons,
            'M': multiIcons,
            'N': undefined,
            'O': virtualIcons,
            'P': eventIcons,
            'Q': virtualIcons,
            'R': earthIcons,
            'S': undefined,
            'T': tradiIcons,
            'U': unknownIcons,
            'V': virtualIcons,
            'W': virtualIcons,
            'X': eventIcons,
            'Y': virtualIcons,
            'Z': eventIcons,
            'Parking Area': createWPIcons('images/parkingArea.gif', [20, 20]),
            'Final Location': createWPIcons('images/final.gif', [20, 20]),
            'Reference Point': createWPIcons('images/referencePoint.gif', [18, 18]),
            'Trailhead': createWPIcons('images/trailhead.gif', [20, 20]),
            'Physical Stage': createWPIcons('images/physicalStage.gif', [20, 20]),
            'Virtual Stage': createWPIcons('images/virtualStage.gif', [20, 20]),
            'Original Coordinates': createWPIcons('images/originalCoordinates.gif', [18, 18])
        };

        function loadCachesAt(x, y, z) {
            var url = gcmarkers.baseurl.replace('{z}', z).replace('{x}', x).replace('{y}', y);
            if (!(url in gcmarkers.caches)) {
                console.info('Loading Geocaches/WPs from ' + url);
                gcmarkers.caches[url] = true;

                var geojsonLayer = L.geoJson.ajax(url, {
                        pointToLayer: function (feature, latlng) {
                            var state = 'defaultAvailable';
                            if (!('wptName' in feature.properties)) {
								state = 'default';								
                                if (feature.properties.foundDate) {
									state = 'found';
                                } else if (feature.properties.hasCorrected) {
                                    state = 'corrected';
                                }
								
								if (feature.properties.status === 'A') {
                                    state += 'Available';
                                } else if (feature.properties.status === 'T') {
                                    state += 'Disabled';
                                } else if (feature.properties.status === 'X') {
                                    state += 'Archived';
                                } 
                            }

                            var markerIcon = L.marker(latlng, {
                                    opacity: 0.9,
                                    icon: icons[feature.properties.type][state],
                                    riseOnHover: true
                                });

                            var tooltipText = "<div><b>";
                            if ('wptName' in feature.properties) {
                                tooltipText = tooltipText + feature.properties.wptName + "-" + feature.properties.desc + "</b><br/><small>" + feature.properties.type + "</small>";
                            } else {
                                tooltipText = tooltipText + feature.properties.desc + "</b>";
                                if (feature.properties.foundDate) {
                                    tooltipText = tooltipText + '<br/><small>Gefunden: ' + formatDate(feature.properties.foundDate) + '</small>';
                                } else {
                                    feature.geometry.coordinates[2] + "m " + feature.properties.county;
                                    if (feature.properties.gefundenVon) {
                                        tooltipText = tooltipText + "<br/><small>" + feature.properties.gefundenVon + "</small>";
                                    }
                                }
								if (feature.properties.archivedDate) {
                                    tooltipText = tooltipText + '<br/><small>Archiviert: ' + formatDate(feature.properties.archivedDate) + '</small>';
                                }
                            }
                            tooltipText = tooltipText + "</div>";

                            markerIcon.bindTooltip(tooltipText, {
                                className: 'gcLabel'
                            });
                            markerIcon.on('click', onMarkerClick);
                            gcmarkers.markers.push(markerIcon);
                            //						console.info(JSON.stringify(feature));
                            return markerIcon;
                        }
                    });

                geojsonLayer.on('data:loaded', function () {
                    gcmarkers.addLayer(geojsonLayer, {
                        chunkedLoading: true,
                        chunkInterval: 200,
                        chunkDelay: 30
                    });
                });
            }
        }

		function formatDate(text) {
			return text.substring(8, 10).replace(/^0+/, '') + '.' + 
				text.substring(5, 7).replace(/^0+/, '') + '.' + 
				text.substring(2, 4);
		}
		
        function toogleTooltips() {
            // Alle Tooltips ein-/ausschalten
            gcmarkers.tooltipsVisible = !gcmarkers.tooltipsVisible;
            gcmarkers.markers.forEach(function (marker) {
                if (gcmarkers.tooltipsVisible) {
                    marker.openTooltip();
                } else {
                    marker.closeTooltip();
                }
            });
        }

        function onMarkerClick(e) {
            window.open('http://coord.info/' + e.target.feature.properties.name);
        }

        return {
            gcmarkers: gcmarkers,
            toggleTooltips: toogleTooltips
        };
    }
};
