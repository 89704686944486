BR.TrackStats = L.Class.extend({
	formatDuration: function(totalSeconds) {
		var hours = Math.floor(totalSeconds / 3600);
        var minutes = Math.floor((totalSeconds - 3600 * hours) / 60);
        var seconds = Math.round(totalSeconds - 3600 * hours - 60 * minutes);
        return (hours > 0 ? hours + 'h ' : '') + minutes + 'min ' + seconds + 's';
	},
	formatHHMM: function(totalSeconds) {
		var hours = Math.floor(totalSeconds / 3600);
        var minutes = Math.round((totalSeconds - 3600 * hours) / 60);
        return (hours > 0 ? hours + 'h ' : '') + minutes + 'min';
	},
	
    update: function (polyline, segments) {
        var stats = this.calcStats(polyline, segments),
            meanCostFactor = stats.trackLength ? L.Util.formatNum(stats.cost / stats.trackLength, 2) : '',
            formattedTime = this.formatDuration(stats.totalTime),
            formattedEnergy = L.Util.formatNum(stats.totalEnergy / 3600000., 2),
            meanEnergy = stats.trackLength ? L.Util.formatNum(stats.totalEnergy / 36. / stats.trackLength, 2) : '';

        $('#distance').html(this.formatDist(stats.trackLength) + 
            ' (' + 
            '&#8594;' + this.formatDist2(stats.flatDistance) +
            ' &#8599;' + this.formatDist2(stats.ascendDistance) +
            ' &#8600;' + this.formatDist2(stats.descendDistance) +
            ')' + ' <abbr title="kilometer">km</abbr>');

        $('#elevation').html(' &#8593;' + stats.filteredAscend + 
            ' &#8595;' + stats.filteredDescend + 
            (stats.plainAscend == 0 ? '' : (' (' + (stats.plainAscend > 0 ? '&#8673;' : '&#8675;') + Math.abs(stats.plainAscend) +')')) + 
            ' <abbr title="meter">m</abbr>');

        if (stats.totalTime > 0) {
          $('#totaltimeBlock').show(0);
          $('#totaltime').html(formattedTime);
        } else {
          $('#totaltimeBlock').hide(0);
          $('#totaltimeBlock').attr('style','display:none !important');
        }

        if (stats.totalEnergy > 0) {
          $('#totalenergyBlock').show(0);
          $('#totalenergy').html(formattedEnergy + ' <abbr title="kWh">kWh</abbr>');
        } else {
          $('#totalenergyBlock').hide(0);
          $('#totalenergyBlock').attr('style','display:none !important');
        }

        if (stats.numberGeocaches) {
          $('#geocachesBlock').show(0);
          $('#geocaches').html(stats.numberGeocaches);
        } else {
          $('#geocachesBlock').hide(0);
          $('#geocachesBlock').attr('style','display:none !important');
        }

        $('#cost').html(stats.cost + ' (' + meanCostFactor + ')');
		
		var durationCaches = 300 * stats.gcTradi +
		600 * stats.gcMulti +
		300 * stats.gcMystery +
		300 * stats.gcOther -
		60 * stats.gcCorrected +
		0.5 * stats.gcAvgDist;
		
		this.infotext = 'Gesamtstrecke: ' + this.formatDist(stats.trackLength) +
			'km  Anstieg: ' + stats.filteredAscend +
			'm  Runter: ' + stats.filteredDescend +
			'm\nZeitbedarf Strecke: ' + formattedTime;
		if (stats.numberGeocaches > 0) {
			this.infotext += '\nAnzahl Caches: ' + stats.numberGeocaches +
			' Zeitbedarf Caches: ' + this.formatDuration(durationCaches) +
			'\nZeitbedarf Total: ' + this.formatHHMM(stats.totalTime + durationCaches);
		}
    },

    formatDist: function(dist) {
        return L.Util.formatNum(dist/1000, dist < 10000 ? 3 : 2);
    },

    formatDist2: function(dist) {
        return L.Util.formatNum(dist/1000, dist < 10000 ? 2 : 1);
    },

	infotext: '',
	
    calcStats: function(polyline, segments) {
        var stats = {
            trackLength: 0,
            filteredAscend: 0,
            filteredDescend: 0,
            plainAscend: 0,
            ascendDistance: 0,
            descendDistance: 0,
            flatDistance: 0,
            totalTime: 0,
            totalEnergy: 0,
            totalTurnCost: 0,
            cost: 0,
            numberGeocaches: 0,
            geocacheCodes: [],
			geocaches: []
        };
        var i, props;

        for (i = 0; segments && i < segments.length; i++) {
            props = segments[i].feature.properties;
            stats.trackLength += +props['track-length'];
            stats.filteredAscend += +props['filtered ascend'];
            stats.filteredDescend += +props['filtered descend'];
            stats.ascendDistance += +props['ascend-distance'];
            stats.descendDistance += +props['descend-distance'];
            stats.flatDistance += +props['flat-distance'];
            stats.plainAscend += +props['plain-ascend'];
            stats.totalEnergy += +props['total-energy'];
            stats.totalTurnCost += +props['total-turncost'];
            stats.cost += +props['cost'];
            stats.geocacheCodes = stats.geocacheCodes.concat(props['geocache-codes']);
            stats.geocaches = stats.geocaches.concat(props['geocaches']);

            var brTotalTime = +props['total-time'];
            var profile = segments[0].feature.properties['name'].toLowerCase();
            if (brTotalTime == 0) {
               var avgSpeedFlat = 28.0;
               var avgSpeedAscend = 22.0;
               var avgSpeedDescend = 34.0;
               var metersPerHour = 1200; // Achtung: Das sind 'zusätzlicher Zeitbedarf'!

               if (profile.indexOf('fast') != -1) {
                  avgSpeedFlat = 30.0;
                  avgSpeedAscend = 23.0;
                  avgSpeedDescend = 36.0;
                  metersPerHour = 1200;
               } else if (profile.indexOf('mtb') != -1) {
                  avgSpeedFlat = 26.0;
                  avgSpeedAscend = 21.0;
                  avgSpeedDescend = 30.0;
                  metersPerHour = 1200;
               } else if (profile.indexOf('hiking') != -1 || profile.indexOf('walking') != -1) {
                  avgSpeedFlat = 5.5;
                  avgSpeedAscend = 5.0;
                  avgSpeedDescend = 6.0;
                  metersPerHour = 1200;
               }

               stats.totalTime = Math.round((stats.flatDistance + stats.totalTurnCost) / avgSpeedFlat * 3.6 +
                                 stats.ascendDistance / avgSpeedAscend * 3.6 +
                                 stats.descendDistance / avgSpeedDescend * 3.6 +
                                 stats.filteredAscend / metersPerHour * 3600);
            } else {
               stats.totalTime = brTotalTime;
            }
//            console.log('dist=' + stats.trackLength + ' ascend-distance=' + stats.ascendDistance + 
//               ' descend-distance=' + stats.descendDistance + ' flat-distance=' + stats.flatDistance +
//                ' total-turncost=' + stats.totalTurnCost + ' total time=' + stats.totalTime +
//                ' flitered Ascend=' + stats.filteredAscend);
        }
        // Doppelte Cache Codes entfernen - Langsam aber bei wenig Codes genug schnell
		stats.geocacheCodes = stats.geocacheCodes.filter(function(elem, index, self) {
    		return index == self.indexOf(elem);
		});
		stats.numberGeocaches = stats.geocacheCodes.length;

		// Geocaches aufbereiten
		var dest = [];
		for (i = 0; i < stats.geocaches.length; i++) {
			var code = stats.geocaches[i].Code;
			// Den Code im dest-Array suchen
			for (j = 0; j < dest.length; j++) {
				if (dest[j].Code === code) {
					break;
				}
			}
			if (j >= dest.length) {
				// Code nicht gefunden ==> Neuer Cache an die dest Liste anhängen
				dest.push(stats.geocaches[i]);
			} else {
				// Cache ist schon in der dest Liste ==>
				// Je nach dem diesen behalten oder löschen und den neuen ans Ende anfügen!
				if (dest[j].Dist <= stats.geocaches[i].Dist) {
					// Behalten
				} else {
					// Löschen und neuer Cache ans Ende anfügen
					dest.splice(j, 1);
					dest.push(stats.geocaches[i]);
				}
			}
		}
		stats.geocaches = dest;
		
		// Ein bisschen Geocache Statistik: 
		// - Anzahl Tradi, Multi, Mysteries, Corrected
		// - Durchschnittliche Abweichung vom Track 
		stats.gcTradi = 0;
		stats.gcMulti = 0;
		stats.gcMystery = 0;
		stats.gcCorrected = 0;
		stats.gcOther = 0;
		stats.gcAvgDist = 0;
		if (stats.geocaches.length > 0) {
			for (i = 0; i < stats.geocaches.length; i++) {
				if (stats.geocaches[i].Type === 'T') {
					stats.gcTradi++;
				} else if (stats.geocaches[i].Type === 'M') {
					stats.gcMulti++;
				} else 
					if (stats.geocaches[i].Type === 'U') {
					stats.gcMystery++;
				} else {
					stats.gcOther++;
				}
				if (stats.geocaches[i].Cor) {
					stats.gcCorrected++;
				}
				stats.gcAvgDist += stats.geocaches[i].Dist;
			}
			stats.gcAvgDist = Math.round(stats.gcAvgDist / stats.geocaches.length);
		}
		
/*		console.log("Anzahl Tradis: " + stats.gcTradi);
		console.log("Anzahl Multis: " + stats.gcMulti);
		console.log("Anzahl Mysteries: " + stats.gcMystery);
		console.log("Anzahl Other: " + stats.gcOther);
		console.log("Anzahl Corrected: " + stats.gcCorrected);
		console.log("Durchschnittlicher Abstand: " + stats.gcAvgDist);*/
        return stats;
    }
});
